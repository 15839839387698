import Link from 'next/link'
import api from '@api/Apis';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

export default function FourOhFour(props) {

    const [show, setShow] = useState(false);
    const { setInnerLoader, clickapi, setLeadIddata } = props;
    const router = useRouter();
    let attemptedPath = router.asPath;


    async function _refferal_form() {
        const formdata = localStorage.getItem('Token') ? localStorage.getItem('Token') : '';

        setInnerLoader(true);
        try {
            const SPLIT_VALUE = _.compact(_.split(attemptedPath, '/', 4));
            const RefferalCode = (SPLIT_VALUE?.length > 0) ? SPLIT_VALUE[0] : '';
            const res = await api.subDomainCheckFunction({ referral_code: RefferalCode });

            if (res?.status === 200) {
                localStorage.setItem("companyName", res?.data?.data?.user_details?.public_company_name);
                localStorage.setItem("publicName", res?.data?.data?.user_details?.public_name)
                setInnerLoader(false)
                setShow(false);

                if (res?.data?.status === true) {
                    Cookies.set('refferal-code', RefferalCode, { expires: 90 })

                    if ((SPLIT_VALUE?.length > 0) && SPLIT_VALUE[1]) {
                        const lead_id = SPLIT_VALUE[2] ? SPLIT_VALUE[2]?.replace(/[^0-9]/g, "") : null;
                        if (lead_id) {
                            localStorage.setItem("leadid", lead_id)
                            setLeadIddata(lead_id)
                            await axios.post(process.env.clickedapi, { lead_id }, { 'Content-Type': 'application/json' }).then((res) => {
                                if (res?.data?.code === 1) {
                                    router.push(`${'/' + SPLIT_VALUE[1]}`);
                                }
                            }).catch((err) => {

                                router.push(`${'/' + SPLIT_VALUE[1]}`);
                            });
                        } else {
                            router.push(`${'/' + SPLIT_VALUE[1]}`);
                        }
                    } else {
                        if ((res?.data?.data?.coprorateSignup === true && !formdata)) {
                            // router.push("/referrersignup")
                            router.push("/")
                        }
                        else {
                            router.push("/")
                        }
                        // router.push('/');

                    }
                    setTimeout(() => setInnerLoader(false), 2000);
                } else {
                    // if (!router.asPath?.includes('perma-link')) {
                    router.push('/');
                    // }
                }
            } else {
                setInnerLoader(false)
                setShow(true);
            }
        } catch (error) {
            setInnerLoader(false);
            console.log(error)
            throw new Error("Internal server error from [page] index", error);
        }
    }

    useEffect(() => {
        if (!attemptedPath?.includes('/us/') && attemptedPath !== "/[page]") {
            _refferal_form();
        } else {
            if (attemptedPath === "/[page]") {
                router.push('/us');
            } else {
                router.push(attemptedPath);
            }
        }

    }, [router.asPath])

    return (
        <>
            {show &&
                <div className="container">
                    <h2 className="title">404 - Page Not Found</h2>
                    <Link href="/"><h3>  <a>Home12</a></h3></Link>
                </div>
            }
        </>
    );
}